.wrapper {
  position: relative;
  padding: 10px 0;
  display: inline-block;

  &.fluid {
    display: block;
  }
  .input {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgb(100, 100, 100);
    border-radius: 25px;
    display: inline-block;
    position: relative;
    z-index: 0;
    margin: 0;
    &.fluid {
      display: block;
      width: 100%;
    }
    &.compact {
      font-size: 0.8em;
    }
  }
  .input + .label {
    color: rgb(140, 140, 150);
    font-size: 1em;
    position: absolute;
    left: 10px;
    top: 15px;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px;
    transition: all 300ms;
    z-index: 2;
    &.compact {
      font-size: 0.8em;
      top: 16px;
    }
  }
  .input + .textEntered,
  .input:focus + .label {
    color: rgb(0, 0, 0);
    font-size: 0.8em;
    position: absolute;
    left: 10px;
    top: 5px;
    background-color: white;
    border-radius: 7px 7px 0 0;
    padding: 0 5px;
    transition: all 300ms;
    z-index: 2;
    &.compact {
      font-size: 0.7em;
    }
  }
}
