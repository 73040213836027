@import "../../assets/styles/_constants.scss";

.header1 {
  font-size: 1.4em;
  font-weight: bold;
}

.arrowButton {
  outline: none;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  margin-right: 5px;
  color: rgb(100, 100, 100);
  &:focus {
    outline: none;
  }
  .buttonIcon {
    color: rgb(100, 100, 100);
    &.liked {
      color: $color3;
      transition: color 300ms;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }
}

.headerWrapper {
  border-bottom: 1px solid rgb(200, 200, 200);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.responsiveWidth {
  min-width: 0;
}

@media (min-width: $sm) {
  .responsiveWidth {
    min-width: 500px;
  }
}
@media (min-width: $md) {
  .responsiveWidth {
    min-width: 650px;
  }
}
@media (min-width: $lg) {
  .responsiveWidth {
    min-width: 800px;
  }
}
