$face: #ffff4c;
$outline: #3d3d3d;
$star: #ffdb19;

$outline-size: 6px;

.star {
  color: rgb(80, 80, 80);
  font-size: 32px;
  margin: 0 10px;
  &.checked {
    color: #ffdb19;
    animation: wobble 0.8s ease-out;
  }
  &:hover {
    cursor: pointer;
  }
}

// Face
.face {
  position: relative;
  width: 200px;
  background: white;
  border: $outline-size solid lighten($outline, 70%);
  border-radius: 100%;
  margin: 50px auto 50px auto;
  transition: box-shadow 0.4s ease-out;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

// Eyes
.eye {
  position: absolute;
  top: 50%;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: lighten($outline, 70%);
  &:nth-child(1) {
    left: 30%;
  }
  &:nth-child(2) {
    right: 30%;
  }
}

// Smile
.smile {
  position: absolute;
  right: 0;
  bottom: 23%;
  left: 0;
  margin: auto;
  width: 42px;
  height: 34px;
  text-decoration: none;
  border: $outline-size solid lighten($outline, 70%);
  border-radius: 100%;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 9px;
    width: $outline-size;
    height: $outline-size - 1;
    background: lighten($outline, 70%);
    border-radius: 60px 60px 0 0;
    z-index: 2;
  }
  &:before {
    left: -$outline-size;
    transform: rotate(-8deg);
  }
  &:after {
    right: -$outline-size;
    transform: rotate(8deg);
  }
  .cover {
    position: absolute;
    top: -$outline-size;
    left: -$outline-size;
    width: 60px;
    height: 16px;
    border: $outline-size solid white;
    background: white;
    transform: translate(0, 0px);
  }
}

// Facial Expressions
.face4,
.face2 {
  &.face {
    .smile {
      width: 60px;
      &:before,
      &:after {
        top: 13px;
        height: 8px;
        width: $outline-size - 0.3;
      }
      &:before {
        left: -4px;
        transform: rotate(-52deg);
      }
      &:after {
        right: -4px;
        transform: rotate(52deg);
      }
      .cover {
        transform: translate(0, 0px);
        height: 23px;
      }
    }
  }
}
.face1,
.face2 {
  &.face {
    .smile {
      transform: rotate(180deg) translateY(-10px);
    }
  }
}
.face3 {
  &.face {
    .smile {
      width: 42px;
      height: $outline-size;
      background: $outline;
      border: none;
      border-radius: 60px;
      transform: translateY(-4px);
      &:before,
      &:after,
      .cover {
        display: none;
      }
    }
  }
}

// Face Color Changes
.checked {
  ~ .face {
    animation: wobble 0.8s ease-out;
  }
  ~ .face,
  ~ .face .smile {
    border-color: $outline;
  }
  ~ .face .eye,
  ~ .face .smile:before,
  ~ .face .smile:after {
    background: $outline;
  }
}
.face1 {
  background-color: lighten($face, 40%);
  animation: wobble 0.8s ease-out;
  box-shadow: inset rgba(white, 0.5) 2px 2px 0 4px,
    inset rgba(black, 0.06) -6px -4px 0 4px;
  .smile .cover {
    background: lighten($face, 40%);
    border-color: lighten($face, 40%);
  }
}
.face2 {
  background-color: lighten($face, 30%);
  animation: wobble 0.8s ease-out;
  box-shadow: inset rgba(white, 0.5) 2px 2px 0 4px,
    inset rgba(black, 0.06) -6px -4px 0 4px, rgba($face, 0.05) 0 0 0 20px;
  .smile .cover {
    background: lighten($face, 30%);
    border-color: lighten($face, 30%);
  }
  &.face {
    animation: wobble 0.8s ease-out;
  }
}
.face3 {
  background-color: lighten($face, 20%);
  box-shadow: inset rgba(white, 0.5) 2px 2px 0 4px,
    inset rgba(black, 0.06) -6px -4px 0 4px, rgba($face, 0.3) 0 0 0 15px,
    rgba($face, 0.1) 0 0 0 30px;
  animation: wobble 0.8s ease-out;
}
.face4 {
  background-color: lighten($face, 10%);
  box-shadow: inset rgba(white, 0.5) 2px 2px 0 4px,
    inset rgba(black, 0.06) -6px -4px 0 4px, rgba($face, 0.4) 0 0 0 20px,
    rgba($face, 0.2) 0 0 0 40px;
  animation: wobble 0.8s ease-out;
  .smile .cover {
    background: lighten($face, 10%);
    border-color: lighten($face, 10%);
  }
}
.face5 {
  background-color: $face;
  box-shadow: inset rgba(white, 0.5) 2px 2px 0 4px,
    inset rgba(black, 0.06) -6px -4px 0 4px, rgba($face, 0.4) 0 0 0 25px,
    rgba($face, 0.2) 0 0 0 50px;
  animation: wobble 0.8s ease-out;
  .smile .cover {
    background: $face;
    border-color: $face;
  }
}

@keyframes wobble {
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}
