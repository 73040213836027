@import "../../assets/styles/_constants.scss";

.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.fieldLabelWrapper {
  flex: 0;
  min-width: 140px;
  font-weight: bold;
}

.fieldInputWrapper {
  flex: 1;
}

@media (min-width: $sm) {
  .fieldWrapper {
    flex-direction: row;
  }
}
