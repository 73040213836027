@import "../../assets/styles/_constants.scss";

.iconButton {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  border: none;
  transition: background-color 300ms;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.md {
    font-size: 20px;
  }
  &.lg {
    font-size: 24px;
    width: 34px;
    height: 34px;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 300ms;
  }
  &.selected {
    color: $color3;
  }
  &.selected:hover {
    color: $color3;
  }
}
