@import "../../assets/styles/_constants.scss";

.kpiValue {
  color: $darkGreen;
  font-weight: bold;
  font-size: 3em;
  &.green {
    color: rgb(71, 235, 1);
  }
  &.red {
    color: rgb(235, 1, 1);
  }
}

.kpiLabel {
  text-align: center;
  max-width: 120px;
  font-weight: bold;
  font-size: 0.9em;
  color: $darkGreen;
}

.kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

@media (min-width: $sm) {
  .kpiValue {
    font-size: 3.3em;
  }
}
@media (min-width: $md) {
  .kpiValue {
    font-size: 3.5em;
  }
}
@media (min-width: $lg) {
  .kpiValue {
    font-size: 3.8em;
  }
}
@media (min-width: $xl) {
  .kpiValue {
    font-size: 4em;
  }
}
