@import "../../assets/styles/_constants.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  .leftWrapper {
    flex: 70%;
    padding-right: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  .rightWrapper {
    flex: 30%;
  }
}

.newPostWrapper {
  overflow: hidden;
  animation: expand 1200ms ease-in-out;
}

.previewContainer {
  padding: 20px;
  background-color: rgb(249, 249, 249);
  //border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  margin-bottom: 20px;
}

.postWrapper {
  position: relative;
  display: flex;
  //border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  box-shadow: 0 0 6px rgb(230, 230, 230);
  transition: box-shadow 300ms;
  flex-direction: column-reverse;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 6px rgb(210, 210, 210);
    transition: box-shadow 300ms;
  }
  &.detail {
    &:hover {
      cursor: auto;
      box-shadow: 0 0 6px rgb(230, 230, 230);
    }
  }

  .reactionWrapper {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.iconButton {
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: none;
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.scoreWrapper {
  background-color: $color0;
  color: $color5;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 5px 0 0 5px;
}

.topicBadge {
  background-color: $color1;
  border: 2px solid $color4;
  border-radius: 10px;
  padding: 1px 10px;
  line-height: 1em;
  margin-right: 3px;
  color: $color5;
  transition: background-color 300ms;
  &:hover {
    background-color: $color2;
    transition: background-color 300ms;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 5px;
  .contentHeaderWrapper {
    font-size: 0.9em;
    display: flex;
    align-items: center;
  }
  .contentTitleWrapper {
    margin-top: 5px;
  }
  .contentBodyWrapper {
    margin: 10px 0;
    flex: 1;
  }
  .contentFooterWrapper {
    font-size: 0.9em;
    .contentFooterItem {
      color: rgb(100, 100, 100);
      font-weight: bold;
    }
  }
}

@media (min-width: $md) {
  .wrapper {
    flex-direction: row;
    .leftWrapper {
      padding-right: 50px;
      margin-bottom: 0;
    }
  }
  .postWrapper {
    flex-direction: row;
  }
}

@keyframes expand {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100%;
  }
}
