@import "./assets/styles/_constants.scss";

@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

html {
  scroll-snap-type: y mandatory;
  //scroll-behavior: smooth;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
}
a {
  color: $lightGreen;
}
.sans {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.serif {
  font-family: "Libre Caslon Text", serif;
}
h1 {
  margin: 0;
  padding: 0;
  color: rgb(80, 80, 80);
  font-family: "Libre Caslon Text", Helvetica, Arial, serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
}
h2 {
  margin: 0;
  padding: 0;
  color: #58595b;
  font-size: 1.7em;

  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.2rem;
  line-height: 1.1;
}

h3 {
  font-size: 1.1em;
  color: #58595b;
  font-weight: bold;
  margin: 0;
}

h4 {
  font-size: 0.9em;
  color: #58595b;
  font-weight: bold;
  margin: 0;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

p {
  margin: 0;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.result-block-container .result-block {
  opacity: 1;
}
.centered {
  text-align: center;
}
.mt1 {
  margin-top: 10px;
}
.mt2 {
  margin-top: 20px;
}
.mt3 {
  margin-top: 30px;
}
.mt4 {
  margin-top: 40px;
}
.mt5 {
  margin-top: 50px;
}
@media (min-width: $sm) {
  h1 {
    font-size: 2.2em;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.13em;
  }
  h4 {
    font-size: 0.94em;
  }
}
@media (min-width: $md) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 0.96em;
  }
  body {
    text-align: left;
  }
}
@media (min-width: $lg) {
  h1 {
    font-size: 2.7em;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.21em;
    font-weight: bold;
  }
  h4 {
    font-size: 0.98em;
  }
}
@media (min-width: $xl) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.62671rem;
  }
  h3 {
    font-size: 1.25em;
    font-weight: bold;
  }
  h4 {
    font-size: 1em;
  }
}
