.wrapper {
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  &.noShadow {
    padding: 0;
    box-shadow: none;
  }
}
