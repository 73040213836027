@import "../../assets/styles/_constants.scss";

.slide {
  min-height: 80vh;
  scroll-snap-align: none;
  position: relative;

  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  &.noSnap {
    scroll-snap-align: none;
  }
}
.headerWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.kpiWrapper {
  background-color: $darkGreen;
  background-image: linear-gradient(to bottom right, $color4, $color3);
  border-radius: 0;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 20px;
  flex-direction: column;
  .kpiSubWrapper {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-around;
  }
  .kpiHeader {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
  }
}
.headerTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 0;
}
.chartsWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.radioWrapper {
  padding: 20px;
}

.heroWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  flex: 1;

  .heroImageWrapper {
    flex: 0;

    .heroImage {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }
  .heroHeaderWrapper {
    flex: 0;
    .heroSubheader {
      font-size: 1em;
      color: #3f5963;
      font-weight: bold;
      margin: 0;
      text-align: center;
      max-width: 80%;
      margin: 10px auto;
    }
    .heroHeader {
      color: #59c5ad;
      font-size: 2em;
      text-align: center;
    }
  }
}

@media (min-width: $sm) {
  .heroWrapper {
    .heroHeaderWrapper {
      .heroHeader {
        font-size: 2.5em;
      }
    }
    .heroImageWrapper {
      .heroImage {
        max-width: 350px;
      }
    }
  }
}

@media (min-width: $md) {
  .slide {
    //scroll-snap-align: start;
    min-height: 100vh;
    &.firstSlide {
      min-height: calc(100vh - 90px);
    }
  }
  .kpiWrapper {
    flex-direction: row;
  }
  .heroWrapper {
    flex-direction: row;

    .heroHeaderWrapper {
      flex: 50%;
      .heroHeader {
        font-size: 3.8em;
        text-align: left;
      }
      .heroSubheader {
        text-align: left;
        margin: 30px 0;
      }
    }
    .heroImageWrapper {
      flex: 50%;
      .heroImage {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: $lg) {
  .heroWrapper {
    .heroHeaderWrapper {
      .heroHeader {
        font-size: 4.2em;
      }
    }
  }
  .chartsWrapper {
    flex-direction: row;
    padding-top: 10px;
  }
}

@media (min-width: $xl) {
  .headerWrapper {
    flex-direction: row;
    align-items: stretch;
  }
  .headerTextWrapper {
    padding-right: 70px;
  }
  .kpiWrapper {
    border-radius: 0 0 0 160px;
    padding: 10px 0 10px 40px;
  }
  .heroWrapper {
    .heroHeaderWrapper {
      .heroHeader {
        font-size: 5em;
      }
    }
  }
  .radioWrapper {
    padding: 0 20px;
  }
}
