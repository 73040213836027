@import "../../assets/styles/_constants.scss";

$dark: rgb(40, 40, 40);
$darkHover: rgb(20, 20, 20);
$lighter: rgb(92, 92, 92);
$selected: $darkGreen;

.wrapper {
  display: flex;
  flex-direction: column;
  color: $dark;
  padding: 40px 0 30px 0;
  min-height: 70vh;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

$calShadow: 0px 0px 12px rgba(6, 49, 21, 0.3);

.calWrapper {
  display: inline-flex;
  flex-direction: column;
  box-shadow: $calShadow;
  overflow: hidden;
  margin-bottom: 30px;

  //border: 1px solid $dark;
  //border-radius: 22px 22px 0 0;
}

.headerWrapper {
  background-color: $color1;
  //border: 1px solid $dark;
  //border-radius: 20px 20px 0 0;
  color: black;
}

.gridWrapper {
  display: inline-flex;
  flex-direction: column;
  opacity: 0;
  //border: 1px solid $dark;
}

.entriesWrapper {
  margin-left: 0;
  background-color: white;
  overflow: hidden;
  flex: 1;
}

.entriesContent {
  opacity: 0;
}

.entriesHeader {
  font-weight: bold;
  border-bottom: 1px solid $dark;
  padding: 25px;
  font-size: 1.7em;
}

.newEntryWrapper {
  padding: 50px;
  text-align: center;
  color: $dark;
}

.icon {
  color: $lighter;
}

$boxHeight: 60px;
.entryBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $boxHeight;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgb(233, 233, 233);
  }
}

.colorBar {
  background-color: $lighter;
  min-height: $boxHeight;
  min-width: 7px;
}

.row {
  display: flex;
  flex-direction: row;
}

.calIcons {
  position: absolute;
  transform: translateY(15px);
  -moz-transform: translateY(15px);
  -webkit-transform: translateY(15px);
  color: red;
  font-size: 0.5em;
}

.dayName {
  min-width: 50px;
  text-align: center;
  background-color: $color5;
  color: white;
  padding: 5px 0;
}

.dayBox {
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    border-top: 1px solid $dark;
    border-right: 1px solid $dark;
  }
  &:last-child {
    border-top: 1px solid $dark;
  }
}

.calButton {
  color: $lighter;
  border: none;
  background: none;
  font-size: 1.4em;
}

.activeDay {
  background-color: white;
  &:hover {
    cursor: pointer;
  }
}

.selected {
  background-color: $selected !important;
  color: white !important;
}

.inactiveDay {
  background-color: #eeeeee;
  color: #858585;
}

.monthHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.arrowButton {
  background-color: $color4;
  color: white;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  margin: 0 20px;
  border: none;
}

.header2 {
  color: $dark;
  font-weight: bold;
}

.symptomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  min-height: 50px;
  padding: 10px 50px;
  margin: 0 auto;
  font-weight: bold;
  font-size: 1.1em;
  &:hover {
    cursor: pointer;
  }
  &:nth-child(even) {
    background-color: #f5f5f5;
  }
  &:nth-child(odd) {
    background-color: #e4e4e4;
  }
}

.method {
  text-align: center;
  border: 1px solid $lighter;
  border-radius: 5px;
  padding: 20px;
  min-width: 120px;
  transition: all 200ms;
  margin: 20px;
  color: $lighter;
  &:hover {
    cursor: pointer;
    transition: all 200ms;
    color: $darkHover;
    box-shadow: 2px 4px 9px rgba(8, 74, 38, 0.3);
  }
}

.dropdown {
  min-width: 500px;
  text-align: center;
  padding: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid rgb(200, 200, 200);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:hover {
    cursor: pointer;
  }
}

.option {
  text-align: center;
  min-width: 100%;
  background-color: none;
  padding: 20px;
}

@media (min-width: $sm) {
  .dayBox {
    min-width: 55px;
    min-height: 55px;
  }
  .dayName {
    min-width: 55px;
  }
  .entriesHeader {
    font-size: 1.9em;
  }
}

@media (min-width: $md) {
  .dayBox {
    min-width: 62px;
    min-height: 62px;
  }
  .dayName {
    min-width: 62px;
  }
  .calIcons {
    transform: translateY(20px);
    -moz-transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  .wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
  .entriesWrapper {
    margin-left: 40px;
  }
  .entriesHeader {
    font-size: 2.2em;
  }
}

@media (min-width: $lg) {
  .dayBox {
    min-width: 72px;
    min-height: 72px;
  }
  .dayName {
    min-width: 72px;
  }
  .calIcons {
    transform: translateY(25px);
    -moz-transform: translateY(25px);
    -webkit-transform: translateY(25px);
  }
  .entriesHeader {
    font-weight: bold;
    border-bottom: 1px solid $dark;
    padding: 25px;
    font-size: 2.4em;
  }
}

@media (min-width: $xl) {
  .dayBox {
    min-width: 90px;
    min-height: 90px;
  }
  .dayName {
    min-width: 90px;
  }
  .calIcons {
    transform: translateY(25px);
    -moz-transform: translateY(25px);
    -webkit-transform: translateY(25px);
  }
  .entriesHeader {
    font-weight: bold;
    border-bottom: 1px solid $dark;
    padding: 25px;
    font-size: 2.5em;
  }
}
