.wrapper {
  background-color: rgb(240, 240, 240);
  cursor: pointer;

  border: none;
  border-radius: 3px;
  padding: 10px;
  transition: background-color 300ms;
  &:hover {
    background-color: rgb(225, 225, 225);
    transition: background-color 300ms;
  }
  &:focus {
    outline: none;
  }
}

.buttonsWrapper {
  border-top: 1px solid rgb(200, 200, 200);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .button {
    border-radius: 10px;
    border: none;
  }
}

.newTime {
  font-weight: bold;
  &.unchanged {
    color: rgb(150, 150, 150);
    transition: color 200ms;
  }
  &.changed {
    color: rgb(0, 0, 0);
    transition: color 200ms;
  }
}

.dropdownWrapper {
  position: absolute;
  background-color: white;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.15);
  color: black;
  z-index: 9;
}

.selections {
  display: flex;
  max-height: 200px;
}

.field {
  overflow: scroll;
}

.fieldOption {
  min-width: 50px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  transition: background-color 200ms;
  &:hover {
    cursor: pointer;
    background-color: rgb(220, 220, 220);
    transition: background-color 200ms;
  }
  &.selected {
    background-color: rgb(100, 220, 100);
  }
}
