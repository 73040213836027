@import "../../assets/styles/_constants.scss";

.flex {
  display: flex;
  &.row {
    flex-direction: row;
  }
  &.rowReverse {
    flex-direction: row-reverse;
  }
  &.column {
    flex-direction: column;
  }
  &.columnReverse {
    flex-direction: column-reverse;
  }
  &.responsiveRow {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
}

.alignItemsCenter {
  align-items: center;
}
.alignItemsFlexStart {
  align-items: flex-start;
}
.alignItemsFlexEnd {
  align-items: flex-end;
}
.alignItemsStretch {
  align-items: stretch;
}

@media (min-width: $sm) {
  .flex {
    &.responsiveRow {
      &.sm {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}

@media (min-width: $md) {
  .flex {
    &.responsiveRow {
      &.md {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}

@media (min-width: $lg) {
  .flex {
    &.responsiveRow {
      &.lg {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}
