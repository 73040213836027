.editorWrapper {
  min-height: 100px;
  border: 1px solid rgb(150, 150, 150);
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  &.isMobile {
    max-width: 90vw;
  }
  &:hover {
    cursor: text;
  }
  &.compact {
    min-height: 0;
  }
}

.button {
  margin-right: 5px;
}
