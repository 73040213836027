@import "../../assets/styles/_constants.scss";

.fieldLabel {
  font-size: 1em;
  font-weight: bold;
  min-width: 70px;
  display: inline-block;
}

.layout {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.leftWrapper {
  flex: 70%;
}

.headerWrapper {
  //border-bottom: 1px solid rgb(170, 170, 170);
  position: relative;
  min-height: 20px;
  .strainHeader {
    text-align: center;
  }
}

.chemistryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.journalWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.journalField {
  flex: 50%;
}

.journalFieldLabel {
  font-weight: bold;
}

.journalFieldValue {
  margin-left: 10px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 50px;
}

@media (min-width: $sm) {
}

@media (min-width: $md) {
  .chemistryWrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
  .headerWrapper {
    .strainHeader {
      text-align: left;
    }
  }
  .layout {
    flex-direction: row;
  }
}

@media (min-width: $lg) {
  .bodyWrapper {
    flex-direction: row;
  }
}
