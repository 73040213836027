@import "../../assets/styles/_constants.scss";

.wrapper {
  //background-image: url("../../assets/leaf.jpeg");
  //background-position: center;
  //background-size: cover;
  background-color: rgb(13, 99, 79);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.heroWrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 50px 20px;
  .newEntryWrapper {
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;

    min-height: 200px;
  }
}
.heroImage {
  width: 100%;
  max-width: 250px;
  height: auto;
}
.heroHeader {
  font-size: 2em;
  text-align: center;
  color: #59c5ad;
}
.heroSubheader {
  font-size: 1em;
  color: $darkGrey;
  font-weight: bold;
  margin: 0;
  text-align: center;
  max-width: 80%;
  margin: 10px auto;
}
.newEntrySubheader {
  font-size: 1.2em;
  color: #59c5ad;
  font-weight: bold;
}
.topBoxWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.quickLinks {
    order: 3;
  }
  &.announcements {
    order: 2;
    margin-bottom: 50px;
  }
}
.boxContainer {
  //border: 2px solid rgb(125, 125, 125);
  //box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  //padding: 10px;
  //border-radius: 10px;
  flex: 1;
}

.dashboardWrapper {
  text-align: center;
}

.gridWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  .gridItem {
    flex: 80%;
    max-width: 80%;
    min-width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 3px 3px 9px rgba(100, 100, 100, 0.2);
    transition: transform 200ms, box-shadow 200ms;
    &:hover {
      cursor: pointer;
      transform: scale(1.017);
      box-shadow: 3px 3px 14px rgba(100, 100, 100, 0.3);
      transition: transform 200ms, box-shadow 200ms;
    }
  }
}

.header3 {
  font-weight: bold;
  font-size: 1.1em;
}

.underHero {
  color: white;
  background-color: #59c5ad;
  padding: 50px 10px;
  .header {
    color: inherit;
    font-size: 3em;
  }
  .description {
    color: #3f5963;
  }
}

.headerButton {
  color: #59c5ad;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: bold;
  font-size: 1.2em;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.divider {
  display: none;
}

.headerLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linksWrapper {
  min-width: 250px;
  margin: 50px 0;
  text-align: center;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.welcomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 50%;
  flex: 1;
  margin: 50px 0;
  order: 1;
}

.welcomeText {
  margin-top: 20px;
  text-align: center;
  flex: 1;
}

.linkList {
  list-style-type: none;
  padding: 0;
  text-align: center;
  .linkItem {
    margin: 10px 0;
    padding: 0;
    text-align: "center";
  }
}

.logo {
  height: 70px;
}

.topHeader {
  margin: 0;
  border-bottom: none;
  padding-bottom: 0;
}

@media (min-width: $sm) {
  .gridWrapper {
    flex-direction: row;
    .gridItem {
      max-width: 30%;
      min-width: 30%;
      flex: 30%;
    }
  }
  .heroHeader {
    font-size: 2.8em;
  }
  .headerLinks {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .linksWrapper {
    margin: 0;
  }

  .topWrapper {
    flex-direction: row;
  }
  .welcomeWrapper {
    margin: 0;
    order: 2;
  }
  .divider {
    display: block;
  }
  .topHeader {
    border-bottom: 1px solid rgb(170, 170, 170);
    padding-bottom: 5px;
  }
  .topBoxWrapper {
    &.announcements {
      order: 1;
    }
  }
}

@media (min-width: $md) {
  .linksWrapper {
    min-width: 300px;
  }
  .heroHeader {
    font-size: 4em;
    text-align: left;
  }
  .heroSubheader {
    text-align: left;
    margin: 10px 0;
  }
  .heroWrapper {
    flex-direction: row;
    padding: 20px 20px;
    .newEntryWrapper {
      text-align: left;
      flex-direction: row;
      justify-content: flex-start;
      min-height: 100px;
    }
  }
  .heroImage {
    max-width: 100%;
  }
}

@media (min-width: $lg) {
  .heroHeader {
    font-size: 5em;
  }
}
