@import "../../assets/styles/_constants.scss";

.header2 {
  padding: 0;
  margin: 0 0 10px 0;
}

.header3 {
  font-size: 1.2em;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid rgb(150, 150, 150);
}

.heroWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  .heroImageWrapper {
    flex: 0;
    text-align: center;
    padding: 30px 30px 0 30px;

    .heroImage {
      width: 100%;
      max-width: 250px;
      height: auto;
    }
  }
  .heroHeaderWrapper {
    flex: 0;
    margin: 0 0 40px 0;
    padding: 0;
  }
}
.strainsWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
}

.strainWrapper {
  flex: 1;
  max-width: 100%;
  //background-color: yellow;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.strainContainer {
  border: 1px solid rgb(170, 170, 170);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }
}

.strainInfoWrapper {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.strainHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  //border-bottom: 1px solid rgb(131, 131, 131);
  flex-direction: column;
}

@media (min-width: $sm) {
  .strainContainer {
    flex-direction: row;
    align-items: stretch;
  }
  .strainInfoWrapper {
    text-align: left;
  }
  .strainHeaderWrapper {
    flex-direction: row;
  }
  .header2 {
    margin: 0;
  }
}

@media (min-width: $md) {
  .strainsWrapper {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
  }
  .strainWrapper {
    flex: 25%;
    max-width: 25%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  .wrapper {
    flex-direction: row;
  }

  .heroWrapper {
    flex-direction: row;

    .heroImageWrapper {
      flex: 50%;
      text-align: right;
      padding: 50px;

      .heroImage {
        max-width: 350px;
      }
    }
    .heroHeaderWrapper {
      flex: 50%;
      margin: 0;
      .heroHeader {
        max-width: 350px;
      }
    }
  }
}
