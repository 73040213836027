@import "../../assets/styles/_constants.scss";

.linkButton {
  border: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  font-size: 1em;
  color: $darkGreen;
  &:hover {
    cursor: pointer;

    color: $darkGreenHover;
  }
  .black {
    color: black;
  }
}

.noBlur {
  &:focus {
    outline: none;
  }
}

.noUnderline {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
