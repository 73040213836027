@import "../../assets/styles/_constants.scss";

.strainContainer {
  border: 1px solid rgb(215, 215, 215);
  border-radius: 15px;
  background-color: white;
  //padding: 20px 10px;
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
  transition: box-shadow 300ms;
  &.noBorder {
    border: none;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 300ms;
  }
}

.strainInfoWrapper {
  text-align: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stetch;
}

.strainHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  color: rgb(80, 80, 80);
  flex-direction: column;
  &.underline {
    border-bottom: 1px solid rgb(200, 200, 200);
  }
}

@media (min-width: $sm) {
  .strainWrapper {
    flex-direction: row;
  }
  .strainContainer {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  .strainInfoWrapper {
    text-align: left;
  }
  .strainHeaderWrapper {
    flex-direction: row;
  }
}

@media (min-width: $md) {
  .strainWrapper {
    flex: 50%;
    align-items: stretch;
    padding: 0 20px;
  }
}
