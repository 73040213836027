@import "../../assets/styles/_constants.scss";

.button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: inline-flex;
  flex: 0;
  align-items: center;
  margin: 0;
  padding: 0;
  line-height: 0;
  font-weight: bold;
  &:hover {
    color: $color3;
  }
  &.selected {
    color: $color4;
  }
  &.selected:hover {
    color: $color4Hover;
  }
}
