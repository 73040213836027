@import "../../assets/styles/_constants.scss";

.wrapper {
  position: relative;
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  .heroBgWrapper {
    background-image: url("../../assets/hand_with_leaf.jpg");
    background-position: center;
    background-size: cover;
  }
  .topicsWrapper {
    flex: 33%;
    display: flex;
    justify-content: stretch;
    .topicsContainer {
      min-width: 300px;
      flex: 1;
    }
  }
  .heroWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 50px 0 30px 0;

    .leftWrapper {
      flex: 33%;
    }
    .heroHeaderWrapper {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      .heroHeader {
        max-width: 300px;
        padding: 30px 0;
      }
      .heroImage {
        width: 200px;
        height: auto;
      }
    }
  }
  .header2 {
    text-align: left;
    border-bottom: 2px solid $darkGreen;
    color: $darkGreen;
    flex: 1;
  }
  .subHeader {
    font-weight: bold;
    color: $darkGrey;
    margin: 40px 0 0 0;
    max-width: 600px;
  }
  .dateTimeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .recentEntriesWrapper {
    margin: 20px 0;
  }
  .myEntriesPreviewWrapper {
    margin: 20px 0;
  }
}

@media (min-width: $md) {
  .wrapper {
    padding: 0;
    .topicsWrapper {
      justify-content: flex-end;
      .topicsContainer {
        flex: 0;
      }
    }
    .heroWrapper {
      flex-direction: row;
      .leftWrapper {
        max-width: 33%;
      }
      .heroHeaderWrapper {
        flex-direction: row;
        justify-content: flex-start;
        .heroHeader {
          max-width: 350px;
          padding: 0 30px 0 0;
          font-size: 3em;
          flex: 0;
        }
        .heroImage {
          width: 200px;
          height: auto;
        }
      }
    }
    .recentEntriesWrapper {
      margin: 0;
    }
    .myEntriesPreviewWrapper {
      margin: 0 30px 0 30px;
    }
    .subHeader {
      margin: 20px 0 0 0;
    }
    .dateTimeWrapper {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}
