.fieldWrapper {
  margin-top: 10px;
  padding-bottom: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid rgb(200, 200, 200);
  }
}

.entryDetailLabel {
  font-weight: bold;
}
