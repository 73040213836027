@import "../../assets/styles/_constants.scss";

$boxHeight: 60px;
.entryBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $boxHeight;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 300ms;
  &.noShadow {
    box-shadow: none;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 300ms;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgb(233, 233, 233);
  }
}

.colorBar {
  background-color: $darkGreen;
  min-height: $boxHeight;
  min-width: 7px;
  flex: 0;
}
