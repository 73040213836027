.wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  opacity: 0;
  animation: fadeIn 200ms forwards;

  .sheetWrapper {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    animation: popUp 300ms forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0;
  }
}
