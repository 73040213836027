@import "../../assets/styles/_constants.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 5;
  min-height: 50px;
  //border-bottom: 1px solid rgb(220, 220, 220);
}

.navLinksWrapper {
  display: none;
}

.navLink {
  &.selected {
    //border-bottom: 2px solid $darkGreen;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      height: 5px;
      background-color: $darkGreen;
    }
  }
}

.leftWrapper {
  display: flex;
  align-items: center;
  padding-left: 15px;
  flex: 1;
}

.rightWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
}

.midWrapper {
  font-weight: bold;
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.listOptions {
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 9999;
  transition: opacity 200ms;

  padding: 0;
  box-shadow: 2px 2px 9px rgba(100, 100, 100, 0.3);
  border-radius: 7px;

  &.hidden {
    opacity: 0;
    z-index: -2;
    transition: opacity 200ms;
  }
  &.noDisplay {
    display: none;
  }
}

.bars {
  color: black;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  left: -20px;
  &:hover {
    cursor: pointer;
    background-color: #dadada;
  }
}

.customSelectOption {
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  //justify-content: flex-end;
  margin: 0;
  white-space: nowrap;
  flex: 1;
  width: 100%;
  background-color: white;
  //border: 1px solid rgb(191, 191, 191);
  //border-left: 1px solid rgb(191, 191, 191);
  //border-right: 1px solid rgb(191, 191, 191);
  text-align: center;
  font-size: 0.85em;
  &:hover {
    background-color: rgb(100, 100, 100);
    color: #ffffff;
    cursor: pointer;
  }
  &:first-of-type {
    border-radius: 7px 7px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 7px 7px;
  }
}

.userButton {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.bigLogo {
  display: none;
}

.smallLogo {
  display: inline;
}

@media (min-width: $md) {
  .smallLogo {
    display: none;
  }
  .bigLogo {
    display: inline;
  }
  .bars {
    display: none;
  }
  .navLinksWrapper {
    display: flex;
    align-items: center;
  }
}
