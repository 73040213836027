@import "../../assets/styles/_constants.scss";

.wrapper {
  margin: 0 auto;
  padding: 40px 20px;
  width: 100%;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 15px 0;
  .header1 {
    text-align: center;
    flex: 0;
    min-width: 200px;
  }
}

.header2 {
  margin: 0;
  text-align: center;
}

.header3 {
  margin: 0;
  font-weight: normal;
  text-align: center;
  font-size: 1em;
}

.header4 {
  display: inline-block;
  font-size: 0.9em;
  margin: 0;
}

.employee {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dataField {
  font-size: 0.9em;
}

.col {
  flex: 50%;
  //min-width: 250px;
}

.largePhoto {
  display: none;
}

.smallPhoto {
  display: block;
}

.employeeTextWrapper {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  flex-wrap: wrap;
  min-width: 200px;
}

.employeeDataWrapper {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

@media (min-width: $sm) {
  .smallPhoto {
    display: none;
  }
  .largePhoto {
    display: block;
  }
  .employee {
    flex-direction: row;
  }
  .header1 {
    text-align: left;
  }
  .header2 {
    text-align: left;
  }
  .header3 {
    text-align: left;
  }
  .employeeTextWrapper {
    padding-left: 20px;
    text-align: left;
  }
}

@media (min-width: $md) {
  .headerWrapper {
    flex-direction: row;
    .header1 {
      min-width: 250px;
    }
  }
}
