@import "../../assets/styles/_constants.scss";

.wrapper {
  flex: 1;
  display: flex;
  background-color: $color5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobileWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  border: 1px solid rgb(133, 133, 133);
  border-radius: 3px;
  padding: 70px 30px;
  min-width: 375px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
}
