.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popUp {
  background-color: white;
  margin: 0 auto;
  padding: 50px;
  text-align: center;
  position: relative;
  &.isMobile {
    max-width: 100vw;
    padding: 25px;
  }
}
