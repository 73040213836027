$lightGreen: #00783f;
$lightGreenDisabled: rgb(176, 214, 172);
$lightGreenHover: #4ca244;
$darkGrey: #3f5963;
$darkGreen: #1fab89;
$darkGreenHover: darken(#1fab89, 10%);
$logoGreen: #c3d251;

$color0: #f0faf0;
$color1: #d7fbe8;
$color2: #9df3c4;
$color3: #62d2a2;
$color4: #1fab89;
$color4Hover: darken(#1fab89, 10%);
$color5: #004f3a;

// Breakpoints

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1500px;
