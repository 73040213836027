@import "../../assets/styles/_constants.scss";

.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.inputLabel {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
}

.textInput {
  display: block;
  font-size: 0.8em;
  padding: 10px;
  //width: calc(100% - 33px);
  margin-top: 5px;
}

.photo {
  margin: 0 auto;
}

.phoneInput {
  font-size: 1em;
  padding: 15px;
  text-align: center;
  display: inline-block;
}

.formWrapper {
  color: rgb(0, 0, 0);
}

.topRight {
  flex: 1;
  padding-left: 0px;
  padding-top: 20px;
}

.header1 {
  color: rgb(50, 50, 50);
  font-size: 1.8em;
  margin: 30px 0;
  font-weight: bold;
  text-align: center;
}

.photoUploadWrapper {
  text-align: center;
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (min-width: $md) {
  .topContainer {
    flex-direction: row;
  }
  .topRight {
    padding-left: 20px;
    padding-top: 0px;
  }
  .photoUploadWrapper {
    text-align: left;
  }
  .inputLabel {
    text-align: left;
  }
  .fieldWrapper {
    text-align: left;
  }
  .photo {
    margin: 0;
  }
  .header1 {
    text-align: left;
    border-bottom: 1px solid rgb(150, 150, 150);
  }
}
