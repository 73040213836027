@import "../../assets/styles/_constants.scss";

.dataLabel {
  font-size: 1em;
  display: inline-block;
}

.dataWrapper {
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-align: left;
}

.dataElement {
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.responsiveRow {
  display: flex;
  flex-direction: column;
}

@media (min-width: $sm) {
  .dataWrapper {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .settingsWrapper {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
  .responsiveRow {
    flex-direction: row;
  }
}
