$greyColor: #454344;
$greenOne: rgb(18, 138, 76);
$greenTwo: rgb(24, 181, 113);
$redOne: rgb(210, 0, 0);
$redTwo: rgb(255, 22, 22);
$blueOne: rgb(101, 150, 255);
$blueTwo: rgb(0, 81, 255);
$yellowOne: rgb(247, 255, 98);
$yellowTwo: rgb(255, 234, 0);
$lineWidth: 2.5rem;
$lineHeight: 0.14rem;
$ballWidth: 0.5rem;
$ballWidthMini: 0.3rem;
$lineColor: rgb(148, 148, 148);
$ballColor: white;
$animeDuration: 1.5s;
$anime: $animeDuration ease-in-out alternate infinite;
$slideX: 15px;
$negSlideX: -15px;
$slideXMini: 9px;
$negSlideXMini: -9px;

.wrapper {
  flex: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  min-width: 50px;
  //background: white;
  &.mini {
    min-height: 80px;
    min-width: 50px;
    .dnaLoader {
      .chain {
        width: 2rem;
        margin: 5px;
        .ball {
          &::before,
          &::after {
            position: relative;
            content: "";
            width: $ballWidthMini;
            height: $ballWidthMini;

            //animation: $anime 0s;
          }
        }
      }
    }
  }
}

.dnaLoader {
  .chain {
    position: relative;
    display: flex;
    align-items: center;
    width: $lineWidth;
    margin: 10px;

    &:nth-child(-n + 8) {
      .ball {
        &.green {
          &::before {
            background-color: $greenTwo;
          }

          &::after {
            background-color: $greenOne;
          }
        }
        &.red {
          &::before {
            background-color: $redTwo;
          }

          &::after {
            background-color: $redOne;
          }
        }
        &.blue {
          &::before {
            background-color: $blueTwo;
          }

          &::after {
            background-color: $blueOne;
          }
        }
        &.yellow {
          &::before {
            background-color: $yellowTwo;
          }

          &::after {
            background-color: $yellowOne;
          }
        }
      }
    }

    &:nth-child(-n + 4) {
      .ball {
        &.green {
          &::before {
            background-color: $greenOne;
          }

          &::after {
            background-color: $greenTwo;
          }
        }
        &.red {
          &::before {
            background-color: $redOne;
          }

          &::after {
            background-color: $redTwo;
          }
        }
        &.blue {
          &::before {
            background-color: $blueOne;
          }

          &::after {
            background-color: $blueTwo;
          }
        }
        &.yellow {
          &::before {
            background-color: $yellowOne;
          }

          &::after {
            background-color: $yellowTwo;
          }
        }
      }
    }

    .line {
      width: 100%;
      height: $lineHeight;
      background: $lineColor;
      opacity: 0;
      animation: scaleXOut $anime, fadeIn 3s 1 normal forwards;
      &.first {
        animation-delay: 0s;
      }
      &.second {
        animation-delay: 0.5s;
      }
      &.third {
        animation-delay: 1s;
      }
      &.fourth {
        animation-delay: 1.5s;
      }
      &.fifth {
        animation-delay: 2s;
      }
      &.sixth {
        animation-delay: 2.5s;
      }
      &.seventh {
        animation-delay: 3s;
      }
      &.eighth {
        animation-delay: 3.5s;
      }
    }

    .ball {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &::before,
      &::after {
        position: relative;
        content: "";
        width: $ballWidth;
        height: $ballWidth;
        background: $ballColor;
        border-radius: 50%;
        opacity: 0;
        //animation: $anime 0s;
      }

      &.first {
        &::before,
        &::after {
          animation-delay: 0s;
        }
      }
      &.second {
        &::before,
        &::after {
          animation-delay: 0.5s;
        }
      }
      &.third {
        &::before,
        &::after {
          animation-delay: 1s;
        }
      }
      &.fourth {
        &::before,
        &::after {
          animation-delay: 1.5s;
        }
      }
      &.fifth {
        &::before,
        &::after {
          animation-delay: 2s;
        }
      }
      &.sixth {
        &::before,
        &::after {
          animation-delay: 2.5s;
        }
      }
      &.seventh {
        &::before,
        &::after {
          animation-delay: 3s;
        }
      }
      &.eighth {
        &::before,
        &::after {
          animation-delay: 3.5s;
        }
      }

      &::before {
        animation: slideRight $anime, fadeIn 2s 1 normal forwards;
      }

      &::after {
        animation: slideLeft $anime, fadeIn 2s 1 normal forwards;
      }
    }
  }
}

@keyframes scaleXOut {
  to {
    transform: scaleX(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX($negSlideX);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX($slideX);
  }
}

@keyframes slideLeftMini {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX($negSlideXMini);
  }
}

@keyframes slideRightMini {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX($slideXMini);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
