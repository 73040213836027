@import "../../assets/styles/_constants.scss";

// Colours -----------------------------------------
$grey: #777;
$grey2: #dfe3e4;
$blue: #83b2dd;
$white: #fff;

// Progress Bar ------------------------------------
.progress {
  position: relative;
  display: flex;

  // Background Track
  .progressTrack {
    position: absolute;
    top: 5px;
    width: 100%;
    height: 5px;
    background-color: $grey2;
    z-index: 0;
  }

  // Each Step on the Progress Bar
  .progressStep {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: center;

    // Hide the final step's progress bar
    &:last-child:before {
      display: none;
    }

    // Step's circle in default state
    &:after {
      content: "";
      display: flex;
      margin: 0 auto;
      margin-bottom: 10px;
      width: 15px;
      height: 15px;
      background: $white;
      border: 4px solid $grey2;
      border-radius: 100%;
      color: $white;
      z-index: 1;
      position: relative;
    }

    // Step's progress bar in default state
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 50%;
      width: 0%;
      transition: width 1s ease-in;
      height: 5px;
      background: $grey2;
      z-index: 0;
    }

    // Step's active state
    &.isActive {
      color: $blue;

      &:after {
        border: 4px solid $grey;
        animation: pulse 2s infinite;
      }
    }

    // Step's complete state
    &.isComplete {
      color: $darkGreen;

      // Step's circle in complete state
      &:after {
        font-family: FontAwesome;
        font-size: 10px;
        color: $white;
        background: $darkGreen;
        border: 4px solid transparent;
        z-index: 2;
      }

      // Step's progress bar in complete state
      &:before {
        background: $blue;
        animation: nextStep 1s;
        animation-fill-mode: forwards;
        z-index: 0;
      }
    }
  }
}

// Animations --------------------------------------
// Pulse animation for Step's circle in active state
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
  }
}

// Progressing to next step animation for Step's progress bar
@keyframes nextStep {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

// General -----------------------------------------
// container
