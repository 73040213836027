.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.colorDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 2px;
}

.red {
  background-color: #f92f49;
}

.blue {
  background-color: #007a9c;
}

.yellow {
  background-color: #ffb800;
}
