@import "../../assets/styles/_constants.scss";

.button {
  background-color: white;
  font-weight: bold;
  color: rgb(150, 150, 150);
  border: 2px solid $darkGreenHover;

  &.selected {
    background-color: $darkGreen;
    color: white;
  }
}
