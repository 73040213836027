.container {
  border: 1px solid rgb(133, 133, 133);
  border-radius: 3px;
  padding: 70px 30px 40px 30px;
  min-width: 375px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  &.noBorder {
    border: none;
  }
}
