@import "../../assets/styles/_constants.scss";

.wrapper {
  display: flex;
  flex-direction: column;
}

.chemistryFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .chemistryFieldLabel {
    font-weight: bold;
    font-size: 0.9em;
  }
}

.chemistryWrapper {
  margin-top: 30px;
}

@media (min-width: $sm) {
  .chemistryFieldWrapper {
    flex-direction: row;
  }
}

@media (min-width: $md) {
  .wrapper {
    flex-direction: row;
  }
  .chemistryFieldWrapper {
    flex-direction: column;
  }
  .chemistryWrapper {
    margin-top: 0;
    text-align: center;
  }
}

@media (min-width: $lg) {
  .chemistryFieldWrapper {
    flex-direction: row;
  }
  .chemistryWrapper {
    text-align: left;
  }
}
