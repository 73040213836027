.calWrapper {
  background-color: white;
  color: black;
  position: absolute;
  z-index: 9;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  background-color: rgb(240, 240, 240);
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 10px;
  transition: background-color 300ms;
  &:hover {
    background-color: rgb(225, 225, 225);
    transition: background-color 300ms;
  }
  &:focus {
    outline: none;
  }
}

.calHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dayBox {
  min-width: 23px;
  min-height: 25px;
  font-size: 12px;
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  &.selected {
    background-color: rgb(0, 240, 0);
  }
}
