@import "../../assets/styles/_constants.scss";

.wrapper {
  flex: 1;
  flex-direction: column;
  //min-height: 100%;
  display: flex;
  max-width: 100vw;
  overflow: hidden;

  .chartAreaWrapper {
    flex: 70%;
    padding: 0;
    .topStrainWrapper {
      flex: 40%;
      padding-left: 15px;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      .topStrainHeader {
        color: $darkGreen;
        font-size: 2em;
      }
    }
  }
  .headerWrapper {
    background-position: center;
    background-color: $darkGreen;
    background-size: cover;
    position: relative;
    padding: 0;
    text-align: left;
    flex: 30%;

    .feelingsContainer {
      position: relative;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: white;
      text-align: inherit;
      .pageBreaks {
        display: none;
      }
      .header {
        color: white;
        text-align: inherit;
      }
      .subHeader {
        //font-weight: bold;
        text-align: inherit;
        font-size: 0.8em;
        line-height: 0.9;
      }
      .select {
        color: white;
      }
    }
    .bgOverlay {
      background-color: rgba(242, 242, 242, 0.468);
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .center {
      text-align: center;
      color: rgb(71, 194, 106);
    }
  }
}

.chartHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.effectsChartWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.chartWrapper {
  max-width: 40%;
}

@media (min-width: $sm) {
  .strainButton {
    flex-direction: column;
    max-width: 33%;
  }
  .strainWrapper {
    flex-direction: row;
  }
}

@media (min-width: $md) {
  .wrapper {
    flex-direction: row;
    .headerWrapper {
      text-align: left;
      padding: 0;
      .feelingsContainer {
        text-align: inherit;

        .header {
          text-align: inherit;
        }
      }
    }
    .chartAreaWrapper {
      padding: 0;
      .topStrainWrapper {
        flex-direction: row;
        align-items: center;
        .topStrainHeader {
          color: $darkGreen;
          font-size: 3em;
          padding-right: 20px;
        }
      }
    }
  }

  .chartWrapper {
    max-width: 100%;
  }
  .chartHeaderWrapper {
    flex-direction: row;
    align-items: flex-end;
  }
}
