@import "../../assets/styles/_constants.scss";

.wrapper {
  padding: 0 20px;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  .headerImageWrapper {
    padding: 20px;
  }
}

.summaryWrapper {
  display: flex;
  flex-direction: column;
  .summaryKpisWrapper {
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    .kpiRow {
      //flex: 50%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .donutWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      padding: 0 200px;
    }
  }
  .summaryChartWrapper {
    flex: 50%;
    max-height: 1000px;
  }
}

.tableHeaderWrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 5px 13px;

  background-color: $darkGreen;
}
.tableHeader {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: bold;
  font-size: 1em;
  color: white;
  text-align: left;
  padding: 5px;

  margin: 0;
  &:focus {
    outline: none;
  }
  &.selected {
    color: white;
  }
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 20px 0;
  //max-height: 1200px;
  .barWrapper {
    flex: 50%;
    min-height: 300px;
  }
  .kpisWrapper {
    flex: 50%;
    .topRowWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}
.header2 {
  color: $darkGreen;
  border-bottom: 2px solid $darkGreen;
}

@media (min-width: $md) {
  .dataWrapper {
    flex-direction: row;
    min-height: 400px;
    max-height: 500px;
    .kpisWrapper {
      .topRowWrapper {
        flex-direction: row;
      }
    }
  }
  .summaryWrapper {
    flex-direction: row;
    .summaryKpisWrapper {
      padding: 0 30px 0 0;
      .donutWrapper {
        padding: 0;
      }
    }
  }
  .headerWrapper {
    flex-direction: row;
    .headerImageWrapper {
      padding: 0 20px;
    }
  }
}
