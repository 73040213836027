@import "../../assets/styles/_constants.scss";

.dot {
  background-color: #ccc;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  transition: all 400ms;
  &.square {
    border-radius: 0;
  }
  &.small {
    min-width: 10px;
    min-height: 10px;
  }
  &.hover {
    box-shadow: 0 0 0 3px $color3, inset 0 0 0 8px $color5;
  }
}

.dotButton {
  background-color: rgba(0, 0, 0, 0);
  padding: 15px;
  &.small {
    padding: 10px;
    &:first-child {
      padding: 10px 10px 10px 0;
    }
  }
  display: flex;
  border: none;
  &:first-child {
    padding: 15px 15px 15px 0;
  }
  &:focus {
    outline: none;
  }
  margin: 0;
  &.readOnly {
    &:hover {
      cursor: auto;
    }
  }
  &.selected {
    .dot {
      background: $color3;
      box-shadow: 0 0 0 2px $color3;
      animation: wobble 0.8s ease-out;
    }
  }
}

.scaleWrapper {
  display: flex;
  align-items: center;
}

.wrapper {
}

.label {
  color: rgb(120, 120, 120);
  font-weight: bold;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  &.active {
    color: rgb(20, 20, 20);
  }
}

.dotWrapper {
  display: flex;
  align-items: center;
}

.rating {
  color: #ccc;
  transition: all 0.3s;
  min-width: 100px;
  text-align: center;
  &.darker {
    color: $color4;
    font-weight: bold;
    animation: wobble 0.8s ease-out;
  }
}

@keyframes wobble {
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}
