@import "../../assets/styles/_constants.scss";

$quickTransition: 300ms;

.button {
  //background-color: $darkGreen;
  background-color: white;

  //color: white;
  color: $darkGreen;
  border-radius: 25px;
  min-width: 50px;
  padding: 10px 20px;
  font-weight: bold;

  border: 2px solid $darkGreen;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow $quickTransition, color $quickTransition;
  &.fluid {
    display: block;
    width: 100%;
  }
  &:hover {
    color: $darkGreenHover;
    border: 2px solid $darkGreen;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow $quickTransition, color $quickTransition;
    cursor: pointer;
  }
  &.checkbox:hover {
    background-color: white;
    color: $darkGreenHover;
    cursor: pointer;
    transition: color $quickTransition background-color $quickTransition;
  }
  &.checkbox.selected:hover {
    background-color: $darkGreen;
    border: 2px solid $darkGreenHover;
    color: white;
    cursor: pointer;
    transition: all $quickTransition;
  }
  &.solid {
    background-color: $darkGreenHover;
    border: 2px solid $darkGreenHover;
    color: white;
    cursor: pointer;
    transition: all $quickTransition;
  }
  &.selected {
    background-color: $darkGreen;
    border: 2px solid $darkGreenHover;

    color: white;
    transition: background-color $quickTransition, color $quickTransition;
    cursor: pointer;
  }
  &.disabled {
    background-color: white;
    border: 1px solid rgb(206, 206, 206);
    color: rgb(191, 191, 191);
    cursor: auto;
  }
  &.compact {
    padding: 5px 12px;
    font-size: 0.95em;
  }
  &.noBlur:focus {
    outline: none;
  }
}

.button.buttonReverse {
  color: $lightGreen;
  background-color: white;
  transition: color $quickTransition, border-color $quickTransition;
  &:hover {
    color: $lightGreenHover;
    border-color: $lightGreenHover;
    transition: color $quickTransition, border-color $quickTransition;
  }
}

.button.red {
  color: red;
  background-color: white;
  border-color: red;
  &:hover {
    color: white;
    background-color: rgb(250, 66, 66);
    transition: color $quickTransition, border-color $quickTransition;
  }
}
