@import "../../assets/styles/_constants.scss";

.wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  transition: background-color 300ms;
  display: flex;
  &.hidden {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 300ms;
    .sideBarWrapper {
      left: -100vw;
      transition: left 300ms;
    }
  }
  .bottomLinks {
    padding: 10px 0 40px 40px;
  }
}

.sideBarWrapper {
  background-color: white;
  min-width: 100vw;
  position: relative;
  left: 0px;
  transition: left 300ms;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.contentWrapper {
  padding: 79px 0px 20px 0px;
}

.link {
  border-top: none;
  border-bottom: 1px solid rgb(200, 200, 200);
  border-right: none;
  border-left: none;
  padding: 20px 40px;
  display: block;
  width: 100%;
  background-color: white;
  font-weight: bold;
  color: rgb(100, 100, 100);
  transition: background-color 300ms;
  text-align: center;
  &.first {
    border-top: 1px solid rgb(200, 200, 200);
  }
  &:hover {
    background-color: rgb(240, 240, 240);
    transition: background-color 300ms;
  }
}

.linkText {
  margin-left: 10px;
}

@media (min-width: $sm) {
  .wrapper {
    &.hidden {
      .sideBarWrapper {
        left: -400px;
      }
    }
  }
  .link {
    text-align: left;
  }
}

@media (min-width: $md) {
}

@media (min-width: $lg) {
}

@media (min-width: $xl) {
}
