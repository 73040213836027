@import "../../assets/styles/_constants.scss";

.wrapper {
  max-width: calc(#{$xs} - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px auto 0 auto;
  align-items: center;
  flex-direction: column;
}

.header1 {
  border-bottom: 1px solid rgb(150, 150, 150);
  text-align: center;
  color: rgb(60, 60, 60);
}

.fieldLabel {
  flex: 15%;
  text-align: left;
  margin: 0;
  font-size: 1em;
}

.fieldInput {
  flex: 80%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 1.2em;
}

.percentLabel::after {
  content: "%";
  display: inline-block;
  position: relative;
  font-size: 2em;
  z-index: 2;
  left: 10px;
}

.colorCircle {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border-width: 3px;
  border-style: solid;
  display: inline-block;
  margin: 4px;
}

@media (min-width: $sm) {
  .wrapper {
    max-width: calc(#{$sm} - 40px);
  }
  .field {
    flex-direction: row;
  }
  .header1 {
    text-align: left;
  }
}

@media (min-width: $md) {
  .wrapper {
    max-width: calc(#{$md} - 40px);
  }
}

@media (min-width: $lg) {
  .wrapper {
    max-width: calc(#{$lg} - 40px);
  }
}
